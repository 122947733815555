import React from "react";
import { Star } from "lucide-react";

import './styles/subject-line.css'

const SubjectLine = (props) => {
	return (
		<div className="subject-span">
			<div className="subject-text">
				{props.title}
			</div>
			<div className="star-icon">
				<Star size={12} color="#7a7a7a" />
			</div>
		</div>
	)
}

export default SubjectLine;