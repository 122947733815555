import React, { useState , useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import "./styles/login-form.css";

function LoginForm() {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [inProp, setInProp] = useState(true); // Manage transition state
	const nodeRef = useRef(null);

    function handleChange(e){
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    function validateInput(){
        if (!formData.email){
			setErrorMessage("Email is Required")
			return false;
		}
        if (!formData.password){
			setErrorMessage("Password is required");
			return false;
		}
        return true;
    };

	async function validateCredentials(){
		return await fetch("/api/login",{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(formData)
		}).then(response => response.json())
		.then(data => {
			if (data.success) {
				return true;
			} else {
				setErrorMessage(data.message)
				return false;
			}
		})
		.catch(error => {
			console.log(error)
			return false
		});
	}

    async function handleLogin(e){
        e.preventDefault();
        if (validateInput() && await validateCredentials() ) {
            // Trigger exit transition
            setInProp(false);

            // Wait for the exit transition to finish before changing the step
            setTimeout(() => {
                window.location.href = "/campaigns.html"
            }, 300); // The duration should match the CSS transition duration
        }
    };

    return (
        <CSSTransition
			nodeRef={nodeRef}
            in={inProp}
            timeout={600} // Transition duration in milliseconds
            classNames="flip"
            unmountOnExit
        >
            <div className="login-component">
                <div
                    className="login-card"
                    style={{ background: 'rgba(255, 255, 255, 0.4)' }}
                >
                    <h2 className="heading">Login</h2>

                    <form className="login-form" onSubmit={handleLogin}>
                        <div style={{width:'100%'}}>
                            <label className="login-form-label" htmlFor="email">Email Address</label>
                            <input
                                type="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="input-field"
                                placeholder="you@example.com"
                            />
                        </div>

                        <div style={{width:'100%'}}>
                            <label className="login-form-label" htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                value={formData.password}
                                onChange={handleChange}
                                className="input-field"
                                placeholder="********"
                            />
                        </div>

						<p className="error-message">{errorMessage}</p>

                        <button
                            type="submit"
                            className="login-button"
                        >
                            Login
                        </button>
                    </form>

                    <p className="paragraph-text">
                        Don't have an account? <Link to="/signup">Sign Up</Link>
                    </p>
                </div>
            </div>
        </CSSTransition>
    );
}

export default LoginForm;
