import Logo from '../assets/logo.png';
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import LoginForm from "../components/login-page/LoginForm";

import "./styles/login.css";

function Login() {
  return (
	<>
		<div className="login-container">
			<Navbar/>
			<div className="row-container">
				{/* Text container */}
				<div className="content-container">

					<div className="heading-text">The Bommber</div>
					<div className="text-content">Launch.Engage.Impact</div>

					<img 
					src={Logo} 
					alt="The Bommber Logo" 
					className="logo-image" // Logo is hidden on small screens
					/>
				</div>

				{/* Form container */}
				<div className="login-form-container">
					<LoginForm />
				</div>
			</div>
		</div>

		<Footer />
	</>
  );
}

export default Login;