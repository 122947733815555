import React from 'react';

import FeatureSection from '../components/home-page/FeatureSection.js';
import Navbar from '../components/Navbar.js';
import LandingSection from '../components/home-page/LandingSection.js';
import BommberOffering from '../components/home-page/BommberOffering.js';
import TemplatesCarousel from "../components/home-page/TemplatesCarousel.js";
import ExperienceSection from '../components/home-page/ExperienceSection.js';
import LearnMoreSection from '../components/home-page/LearnMoreSection.js';
import Footer from "../components/Footer.js"

import './styles/home.css';

const Home = () => {
    return (
        <div className="home">
                <Navbar />
            <header>
                {/* background circles */}
                <div className="circle yellow"></div>
                <div className="circle red"></div>
                <div className="circle blue"></div>
                <LandingSection />
                <BommberOffering />
            </header>
            <div className='feature-to-experience'> {/*features to exp component */}
                <FeatureSection />
				<TemplatesCarousel />
                <ExperienceSection />
            </div>
			<LearnMoreSection />
			
			<Footer />
        </div>
    );
};

export default Home;
