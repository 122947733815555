import React from 'react';
import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import './styles/cancellation-refund.css';

const CancellationAndRefund = () => {
  return (
  	<>
		<Navbar/>
		<div className="container">
			<div className="page-title">Cancellation and Refund</div>

			<div className="cancellation-and-refund-content">
				<p className="last-updated">Last updated: July 21, 2024</p>
				<p>
					We believe in helping our customers as far as possible, and have therefore a liberal cancellation policy. Under this policy:
				</p>
				<ul>
					<li>
						Cancellations will be considered only if the request is made within 2 days of placing the order. However, the cancellation request may not be entertained if the subscription has been used to ship and communicated to the vendors/merchants, and they have initiated the process of using them.
					</li>
					<li>
						In case of receipt of damaged or defective items, please report the same to the Brand's Customer Service team.
					</li>
					<li>
						If you feel that the product received is not as shown on the site or does not meet your expectations, you must bring it to the notice of our customer service within 2 days of receiving the product. The Customer Service Team will take an appropriate decision after reviewing your complaint.
					</li>
					<li>
						For complaints regarding products that come with a warranty from manufacturers, please refer the issue to them.
					</li>
					<li>
						In case of any refunds approved by The Bommber, it will take 3-5 days for the refund to be processed to the end customer.
					</li>
				</ul>
			</div>
		</div>
		<Footer />
	</>
  );
};

export default CancellationAndRefund;
