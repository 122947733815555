import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ChevronLeft } from 'lucide-react';

import './styles/signup-form-section2.css'; // Custom CSS animations

function SignupFormSection2({ setStep, formData, handleFormDataChange }) {
	const [errorMessage, setErrorMessage] = useState("");
	const [inProp, setInProp] = useState(true); // Manage transition state

	function handleChange(e) {
		const { id, value } = e.target;
		handleFormDataChange({
			[id]: value,
		});
	};

	function validateInput(){
		if (!formData.brandname){
			setErrorMessage('Brand Name is required');
			return false;
		}
		if (!formData.brandwebsite ){
			setErrorMessage('Brand Website URL is required');
			return false;
		}
		if (!formData.brandlogo){
			setErrorMessage('Brand Logo URL is required');
			return false;
		}

		return true;
	};

	function handleNext(e){
		e.preventDefault();
		if (validateInput()) {
			if( !formData.brandmail ){
				formData.brandmail = formData.brandname.replace(/\s+/g, '').toLowerCase() + "@thebommber.com";
			}

			setInProp(false); // Start the exit transition
			setTimeout(() => {
				setStep(3, { ...formData }); // Pass formData to the next step
			}, 600); // The duration should match the CSS transition duration
		}
	};

	function handleBack(){
		setInProp(false); // Start the exit transition
		setTimeout(() => {
			setStep(1); // Go back to step 1
		}, 600);
	};

	return (
		<CSSTransition
			in={inProp}
			timeout={600}
			classNames="flip"
			unmountOnExit
		>
			<div className="signup-form-section2"> 
			<div className="signup-form-section2-card">
				<h2 className="signup-form-section2-heading">Sign Up</h2>

				<div className="form-section-row-container">
					{/* Back Arrow Button */}
					<ChevronLeft size={36} onClick={handleBack}/>

					<form onSubmit={handleNext}>
						<div className="form-subsection">
							<label htmlFor="brandName">Brand Name</label>
							<input
								type="text"
								id="brandname"
								value={formData.brandname}
								onChange={handleChange}
								placeholder="Brand Name"
							/>
						</div>

						{/* Brand Website URL Field */}
						<div className="form-subsection">
							<label htmlFor="brandwebsite">Brand Website URL </label>
							<input
								type="url"
								id="brandwebsite"
								value={formData.brandwebsite}
								onChange={handleChange}
								placeholder="https://example.com"
							/>
						</div>

						<div className="form-subsection">
							<label htmlFor="brandlogo">Brand Logo URL</label>
							<input
								type="url"
								id="brandlogo"
								value={formData.brandlogo}
								onChange={handleChange}
								placeholder="https://example.com/logo.png"
							/>
						</div>

						<p className="error-message">{errorMessage}</p>

						<div className="form-step">2/4</div>
						<button type="submit">Next</button>
					</form>
				</div>

				<p className="signup-text">
					Already have an account?<a href="/login">Login</a>
				</p>
			</div>
			</div>
		</CSSTransition>
	);
}

export default SignupFormSection2;
