import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './styles/signup-form-section1.css';

function SignupFormSection1({ setStep, formData, handleFormDataChange }) {
	const [errorMessage, setErrorMessage] = useState("");
	const [inProp, setInProp] = useState(true);

	function handleChange(e){
		const { id, value } = e.target;
		handleFormDataChange({
			[id]: value,
		});
	};	

	function validateInput(){
		if (!formData.email){
			setErrorMessage('Email is required');
			return false;
		}
		if (!formData.firstname){
			setErrorMessage('First name is required');
			return false;
		}
		if (!formData.lastname){
			setErrorMessage('Last name is required');
			return false;
		}

		return true;
	};

	function handleNext(e){
		e.preventDefault();
		if (validateInput()) {
			setInProp(false);

			setTimeout(() => {
				setStep(2); // Proceed to the next step
			}, 600); // The duration should match the CSS transition duration
		}
	};

	return (
		<CSSTransition
			in={inProp}
			timeout={600} // Transition duration in milliseconds
			classNames="flip"
			unmountOnExit
			>
			<div className="signup-form-section1">
				<div className="signup-form-section1-card">
					<h2 className="signup-form-section1-heading">Sign Up</h2>

					<form onSubmit={handleNext}>
						<div className="form-subsection">
							<label htmlFor="email">Email Address</label>
							<input
							type="email"
							id="email"
							value={formData.email} // Controlled input from parent
							onChange={handleChange}
							placeholder="you@example.com"
							/>
						</div>

						<div className="form-subsection">
							<label htmlFor="firstname">First Name</label>
							<input
								type="text"
								id="firstname"
								value={formData.firstname} // Controlled input from parent
								onChange={handleChange}
								placeholder="John"
							/>
						</div>

						<div className="form-subsection">
							<label htmlFor="lastname">Last Name</label>
							<input
								type="text"
								id="lastname"
								value={formData.lastname} // Controlled input from parent
								onChange={handleChange}
								placeholder="Doe"
							/>
						</div>

						<p className="error-message ">{errorMessage}</p>

						<div className="form-step">1/4</div>

						<button type="submit">Next</button>
					</form>
					<p className="signup-text">
						Already have an account? <a href="/login">Login</a>
					</p>
				</div>
			</div>
		</CSSTransition>
	);
}

export default SignupFormSection1;
