import React from "react";

import "./styles/sender-info.css"

const SenderInfo = (props) => {
	return (
		<div className='sender-info'>
			<div className="sender-details">
				<div className="sender-profile-picture">{props.sender_initial}</div>&nbsp;&nbsp;&nbsp;
				<div className="sender-name">
					<p>{props.sender_name} &nbsp;&nbsp;&nbsp;12:42 PM<br />
						to me</p>
				</div>
			</div>
			<span>Unsubscribe &nbsp;&nbsp;&nbsp;&nbsp;⋮&nbsp;&nbsp;</span>

		</div>
)
}

export default SenderInfo;