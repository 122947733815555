import React from 'react';

import './styles/feature-section.css'

const ExperienceSection = () => {
    return (
        <>
            <div className="features-container">
                <h2 className="features-title">Included in Your Experience</h2><br /><br />
                <div className='grid_content'>

                    <div className="features-grid">
                        <div className="feature-card">

                            <div className="feature-content">
                                <h3>Better Engagement</h3>
                                <p>Boost customer interaction with engaging and interactive emails that stand out in their inbox.</p>
                            </div>
                        </div>
                        <div className="feature-card">
                            <div className="feature-content">
                                <h3>Save Time</h3>
                                <p>Streamline your email marketing process with easy-to-use tools and templates, saving you valuable time.</p>
                            </div>
                        </div>
                        <div className="feature-card">
                            <div className="feature-content">
                                <h3>Detailed Insights</h3>
                                <p>Gain comprehensive insights into your email campaigns to make informed decisions and improve results.</p>
                            </div>
                        </div>
                        <div className="feature-card">

                            <div className="feature-content">
                                <h3>Increased Revenue</h3>
                                <p>Drive sales directly from emails and see a significant increase in your revenue.</p>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</>
    );
};

export default ExperienceSection;