import React from 'react';
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";

import './styles/about-us.css';

const AboutUs = () => {
    return (
		<>
			<Navbar />

			<div className="about-us">
				<div className="about-us-container">
					<section className="about-section">
						<h1 className="section-title" style={{fontSize:"36px"}}>About Us</h1>
						<p className="section-paragraph">
							Welcome to <span className="highlight">The Bommber!</span> We are dedicated to revolutionising the way businesses interact with their customers through interactive email marketing. Our platform allows businesses to send interactive emails that not only captivate the audience but also enable direct shopping and engagement, all from within the email itself.
						</p>
					</section>

					<section className="mission-section">
						<h2 className="section-subtitle">Our Mission</h2>
						<p className="section-paragraph">
							At The Bommber, our mission is to enhance customer engagement and boost revenue for businesses by leveraging the power of interactive email marketing. We aim to make the email experience more enjoyable and functional, turning every email into an opportunity for engagement and conversion.
						</p>
					</section>

					<section className="offer-section">
						<h2 className="section-subtitle">What We Offer</h2>
						<ul className="offer-list">
							<li><strong>Interactive Emails:</strong>  Our emails are not just static messages; they are dynamic and interactive, allowing customers to engage directly with your content.</li><br />
							<li><strong>Shopping Functionality:</strong>  Customers can shop directly from the email, adding items to their cart and making purchases without ever leaving their inbox.</li><br />
							<li><strong>Personalised Content:</strong>  Tailor your emails to each recipient with personalized content that speaks directly to their interests and needs.</li><br />
							<li><strong>Analytics & Insights:</strong>  Gain valuable insights into how your emails are performing with our detailed analytics dashboard.</li><br />
							<li><strong>Easy Integration:</strong>  Our platform integrates seamlessly with your existing systems, making it easy to incorporate interactive emails into your marketing strategy.</li><br />
						</ul>
					</section>

					<section className="why-us-section">
						<h2 className="section-subtitle">Why Choose Us?</h2>
						<ul className="why-us-list">
							<li><strong>Innovation:</strong>  We are constantly innovating to bring you the latest and most effective email marketing solutions.</li><br />
							<li><strong>Customer Focus:</strong>  Our platform is designed with your customers in mind, ensuring a seamless and enjoyable experience for them.</li><br />
							<li><strong>Results-Driven:</strong>  Our goal is to help you achieve measurable results and grow your business through effective email marketing.</li><br />
							<li><strong>Support:</strong>  We provide comprehensive support to help you get the most out of our platform, from setup to execution and beyond.</li><br />
						</ul>
					</section>

					<section className="disclaimer-section">
						<h2 className="section-subtitle">Disclaimer</h2>
						<p className="section-paragraph">
							Please note that The Bommber facilitates interactive email marketing campaigns but is not responsible for the products that you may receive through such emails. Any issues related to product quality, damages, or returns should be directed to the seller whose contact information is provided within the email you received. We are not liable for any damages or discrepancies related to the products offered by these sellers.
						</p>
					</section>

					<section className="contact-section">
						<h2 className="section-subtitle">Contact Us</h2>
						<p className="section-paragraph">
							We would love to hear from you! If you have any questions or would like to learn more about how The Bommber can help your business, please feel free to reach out to us:
						</p>
						<p className="section-paragraph"><strong>Email:</strong> abhishek@thebommber.com</p>
						<p className="section-paragraph"><strong>Phone:</strong> +91 9819099252</p>
					</section>
				</div>
			</div>
			<Footer/>
		</>
    );
};

export default AboutUs;