import React from 'react';
import Image from '../../assets/prelaunch-template.png';

import MailHeader from './sub-components/MailHeader';
import SubjectLine from './sub-components/SubjectLine';
import SenderInfo from './sub-components/SenderInfo';
import PhoneFrame from './PhoneFrame';

import './styles/mail-template.css'

const PrelaunchTemplate = () => (
	<PhoneFrame>
		<MailHeader />
		<SubjectLine title="Hii Joshn! Kursi ki peti bandh lijiye kyuki our new launch is coming sooon..." />
		<SenderInfo sender_initial="S" sender_name="Something" />
		<div className="emailprod">
			<img src={Image} alt="" />
		</div>
	</PhoneFrame>
);

export default PrelaunchTemplate;