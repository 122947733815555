import React, { useState } from 'react';

import MailHeader from './sub-components/MailHeader';
import SubjectLine from './sub-components/SubjectLine';
import SenderInfo from './sub-components/SenderInfo';

import NikeBanner from "../../assets/nike-banner.png";
import Shoes from "../../assets/shoes.png";
import Socks from '../../assets/socks.png';

import './styles/mail-template.css';

import PhoneFrame from "./PhoneFrame"

const SellTempalte1 = () => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [orderConfirmed, setOrderConfirmed] = useState(false);

    // Placeholder for cart items
    const cartItems = [
        {
            id: 1,
            name: 'Sustainable Materials Nike V2K Run',
            category: 'Shoes',
            price: '₹ 10,795.00',
            img: `${Shoes}`,
            rating: 4,
            size: 'UK 7.5'
        },
        {
            id: 2,
            name: 'Nike Everyday Lightweight Training Crew Socks (3 Pairs)',
            category: 'Socks',
            price: '₹ 595.00',
            img: `${Socks}`,
            rating: 4,
            size: '8-11'
        }
    ];

    const nextButtonOnClick = () => {
        setShowConfirmation(true);
    };

    const confirmButtonOnClick = () => {
        setShowConfirmation(false);
        setOrderConfirmed(true);
    };

    const cancelButtonOnClick = () => {
        setShowConfirmation(false);
    };

    return (
		<PhoneFrame>
			<MailHeader />
			<SubjectLine title="Hey! We noticed you left some items in your cart. Checkout now within this mail!" />
			<SenderInfo sender_initial="N" sender_name="Nike" />

			<div className="mail-container">
				<div className="banner-image">
						<img src={NikeBanner} alt="Banner" />
				</div>
				
				<h1>Your Cart</h1>
				<p>No redirection, shop within this Email</p>
				<p style={{ textAlign: 'left' }}>Hey! We noticed you left items in your cart.</p>

				{cartItems.map(item => (
					<div key={item.id} className="cart-item">
						<img src={item.img} alt={item.name} className="cart-item-image" />
						<div className="cart-item-details">
							<h3>{item.name}</h3>
							<p>{item.category}</p>
							<p>MRP: {item.price} <span>incl. of taxes</span></p>
							<div className="cart-item-rating">
								{'★'.repeat(item.rating)}{'☆'.repeat(5 - item.rating)}
							</div>
							<div className="cart-item-size">
								<label>Select Size</label>
								<h4>{item.size}</h4>
							</div>
							<div className="cart-item-controls">
								<p>&nbsp;
									&nbsp;━&nbsp;&nbsp;&nbsp;1&nbsp;&nbsp;&nbsp; ╋</p>
							</div>
						</div>
					</div>
				))}

				<div className="cart-next">
					<button className="next-button" onClick={nextButtonOnClick}>Next</button>
				</div>

				{/* Confirmation Popup */}
				{showConfirmation && (
					<div className="confirmation-popup">
						<h3>Confirm your Order</h3>
						<p>Are you sure you want to place the order?</p>
						<button onClick={confirmButtonOnClick}>Confirm</button>
						<button onClick={cancelButtonOnClick}>Cancel</button>
					</div>
				)}

				{/* Success Message */}
				{orderConfirmed && (
					<div className="success-message">
						<h3>Order Placed Successfully!</h3>
						<p>Thank you for your purchase. You will receive an email confirmation shortly.</p>
					</div>
				)}

				<div className="price-details">
					<h3>Price Details</h3>
					<p>Total MRP: <span>₹ 11,390</span></p>
					<p>Shipping Charges: <span>FREE</span></p>
					<h4>Total Amount: <span>₹ 11,390.00</span></h4>
				</div>
			</div>
		</PhoneFrame>
    );
}

export default SellTempalte1;
