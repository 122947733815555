import React from 'react';
import SellTempalte1 from '../mail-templates/SellTemplate1';

import './styles/landing-section.css'

const LandingSection = () => {
    const scrollToFeature = () => {
        const featureSection = document.getElementById('feature-section');
        if (featureSection) {
            featureSection.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
		<div className="landing-section">
            <div className="info-section">
                <h1>Launch. Engage. Impact.</h1>
                <h2>Empower your brand with Interactive Email Marketing</h2>
                <br />
                <h2 style={{marginTop:'30px'}}>Say goodbye to the hassle of being redirected to a brand's website.</h2>
                <button onClick={scrollToFeature}>Learn More</button>
            </div>

			<div className="template-display-section">
				<SellTempalte1 />
			</div>
        </div >
    );
};

export default LandingSection;