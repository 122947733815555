import React from 'react';
import { Link } from 'react-router-dom';

import './styles/learn-more-section.css';

const LearnMoreSection = () => {
    return (
        <div className="email">
            <h1>Experience the Power of Email</h1>
            <h3>Unlock the convenience of purchasing products directly from your email.</h3>
            <div className="email-section-buttons">
                <button><a style={{textDecoration: 'none' , color:'black'}} href="/signup.html">Sign Up</a></button>
                <button > <Link style={{ textDecoration: 'none', color: 'white' }} to="/about-us">Learn More</Link> </button>
            </div>
        </div>
    );
};

export default LearnMoreSection;
