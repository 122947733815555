import React from 'react';
import Image3 from '../../assets/sell-template-2.png';

import MailHeader from './sub-components/MailHeader';
import SubjectLine from './sub-components/SubjectLine';
import SenderInfo from './sub-components/SenderInfo';
import PhoneFrame from './PhoneFrame';

import './styles/mail-template.css';

const SellTemplate2 = () => (
	<PhoneFrame>
		<MailHeader />
		<SubjectLine title="Hey! We have restocked our bestsellers. Checkout now within this mail!" />
		<SenderInfo sender_initial="JS" sender_name="JewelStore" />
		<div className="emailprod">
			<img src={Image3} alt="" />
		</div>
	</PhoneFrame>
);

export default SellTemplate2;
