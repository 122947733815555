import React from 'react';
import Image from '../../assets/quiz-template.png'

import MailHeader from './sub-components/MailHeader';
import SubjectLine from './sub-components/SubjectLine';
import SenderInfo from './sub-components/SenderInfo';
import PhoneFrame from './PhoneFrame'

import './styles/mail-template.css'

const CarouselTemplate4 = () => (
	<PhoneFrame>
		<MailHeader />
		<SubjectLine title="Help us know you better, we are rebranding. Answer theses simple questions." />
		<SenderInfo sender_initial="MS" sender_name="MegaStore" />
		<div className="emailprod">
			<img src={Image} alt="" />
		</div>
	</PhoneFrame>

);

export default CarouselTemplate4;
